import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

import { SearchablePopoverComponent } from 'src/app/components/searchable-popover/searchable-popover.component';
import {
  CONVERSATION_PRIORITY_VALUES,
  CONVERSATION_STATUS,
  SUPPORT_STEP_ASSIGNEE_TYPES,
  SUPPORT_STEP_KEYWORDS,
  routes,
} from 'src/app/constants';
import { environment } from 'src/environments/environment';
import {
  Automation,
  Conversation,
  Customer,
  Tag,
  Template,
  TicketType,
  WhereClause,
  Profile,
  ListResponse,
  ExternalCrmAccount,
} from 'src/models';
import { ApiService } from 'src/services/api.service';
import { ConversationsService } from 'src/services/conversations.service';
import { CustomersService } from 'src/services/customers.service';
import { TagsService } from 'src/services/tags.service';
import { UserTeamsService } from 'src/services/user-teams.service';
import { UsersService } from 'src/services/users.service';

import { AutomationsService } from './automations.service';
import { SlackService } from './slack.service';
import { TicketTypesService } from './ticket-types.service';

type PopoverSide = 'top' | 'bottom' | 'left' | 'right';

export const STATUS_OPTIONS = [
  {
    id: CONVERSATION_STATUS.OPEN,
    title: 'Needs response',
  },
  {
    id: CONVERSATION_STATUS.IN_PROGRESS,
    title: 'In progress',
  },
  {
    id: CONVERSATION_STATUS.ON_HOLD,
    title: 'On hold',
  },
  {
    id: CONVERSATION_STATUS.CLOSED,
    title: 'Closed',
  },
];

export const PRIORITY_OPTIONS = [
  {
    id: String(CONVERSATION_PRIORITY_VALUES.LOW),
    title: 'Low',
  },
  {
    id: String(CONVERSATION_PRIORITY_VALUES.MEDIUM),
    title: 'Medium',
  },
  {
    id: String(CONVERSATION_PRIORITY_VALUES.HIGH),
    title: 'High',
  },
  {
    id: String(CONVERSATION_PRIORITY_VALUES.CRITICAL),
    title: 'Critical',
  },
];

const NONE_TEXT = 'None';

function formatUserName(user: Partial<Profile>, connectedTeams: any[]) {
  const name = user.name?.trim() || user.email?.trim() || 'No Name';
  return `${name}${connectedTeams?.length > 1 && user.slackTeam?.name ? ` (${user.slackTeam.name})` : ''}`;
}

@Injectable({
  providedIn: 'root',
})
export class SearchablePopoverService {
  constructor(
    private popoverCtrl: PopoverController,
    private usersService: UsersService,
    private userTeamsService: UserTeamsService,
    private tagsService: TagsService,
    private apiService: ApiService,
    private customersService: CustomersService,
    private conversationsService: ConversationsService,
    private ticketTypesService: TicketTypesService,
    private automationsService: AutomationsService,
    private slackService: SlackService,
  ) {}

  public async showPopover({
    selectedItemId,
    callback,
    loadData,
    showImages,
    showSearch,
    placeholderLink,
    dynamicCreationCallback,
    event,
    side,
    componentView,
  }: {
    callback: (id: string, user: any, object: any) => void;
    event: any;
    selectedItemId?: string;
    loadData: (searchText: string) => Promise<any[]>;
    showImages?: boolean;
    showSearch?: boolean;
    placeholderLink?: string;
    dynamicCreationCallback?: (searchText: string) => void;
    side?: PopoverSide;
    componentView?: string;
  }) {
    const popover = await this.popoverCtrl.create({
      component: SearchablePopoverComponent,
      componentProps: {
        selectedItemId,
        showImages,
        showSearch,
        placeholderLink,
        dynamicCreationCallback,
        callback,
        loadData,
        componentView,
      },
      showBackdrop: false,
      side,
      event,
    });

    popover.present();
  }

  public async users({
    callback,
    event,
    selectedUserId,
    side,
    dedupeInternalUsers,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedUserId?: string;
    side?: PopoverSide;
    dedupeInternalUsers?: boolean;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedUserId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'isAutoAssignable',
            operator: '!=',
            value: false,
          },
          {
            field: 'status',
            operator: '==',
            value: 'active',
          },
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }
        const result = await this.usersService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
          dedupeInternalUsers,
        });

        const data = [];

        if (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase())) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: '',
            object: {},
          });
        }

        const connectedTeams = await this.slackService.getConnectedTeams();

        result.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });

        return data;
      },
    });
  }

  public async assignees({
    callback,
    event,
    selectedId,
    showPrimaryRepOption,
    showCreatedByUserOption,
    includeNonAgents = false,
    onlyMentionable = false,
    dedupeInternalUsers,
    showNoneOption,
    side,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedId?: string;
    showPrimaryRepOption: boolean;
    showCreatedByUserOption: boolean;
    includeNonAgents?: boolean;
    onlyMentionable?: boolean;
    dedupeInternalUsers?: boolean;
    showNoneOption: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'status',
            operator: '==',
            value: 'active',
          },
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        if (!includeNonAgents) {
          where.push({
            field: 'isAutoAssignable',
            operator: '!=',
            value: false,
          });
        }

        if (onlyMentionable) {
          where.push({
            field: 'slackId',
            operator: '!=',
            value: null,
          });
        }

        const userTeamWhere: WhereClause[] = [
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
          {
            field: 'slackId',
            operator: '!=',
            value: null,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
          userTeamWhere.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const [users, userTeams] = await Promise.all([
          this.usersService.list({
            where,
            limit: 100,
            order: ['name'],
            descending: true,
            dedupeInternalUsers,
          }),
          this.userTeamsService.list({
            limit: 100,
            order: ['name'],
            descending: false,
            where: userTeamWhere,
          }),
        ]);

        const data = [];
        const noneText = 'No one';
        const primaryRepText = 'Primary support rep';
        const secondaryRepText = 'Secondary support rep';
        const assigneeManagerText = "Assignee's manager";
        const submitterManagerText = "Submitter's manager";
        const userCreatedCustomerText = 'User who created the customer';

        if (showNoneOption && (!searchText?.length || noneText.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: noneText,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        if (
          showPrimaryRepOption &&
          (!searchText?.length ||
            primaryRepText.toLowerCase().includes(searchText.toLowerCase()) ||
            secondaryRepText.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          data.push(
            {
              title: primaryRepText,
              id: SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: secondaryRepText,
              id: SUPPORT_STEP_KEYWORDS.SECONDARY_SUPPORT_ASSIGNEE,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: assigneeManagerText,
              id: SUPPORT_STEP_KEYWORDS.ASSIGNEE_MANAGER,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
            {
              title: submitterManagerText,
              id: SUPPORT_STEP_KEYWORDS.SUBMITTER_MANAGER,
              image: environment.assetsBaseUrl + '/imgs/1x1.png',
              object: {},
            },
          );
        }

        if (
          showCreatedByUserOption &&
          (!searchText?.length || userCreatedCustomerText.toLowerCase().includes(searchText.toLowerCase()))
        ) {
          data.push({
            title: userCreatedCustomerText,
            id: SUPPORT_STEP_KEYWORDS.CREATED_BY_USER,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        userTeams.data.forEach((team) => {
          data.push({
            id: team.id,
            title: `${team.name} (Team)`,
            image: '',
            object: {
              type: SUPPORT_STEP_ASSIGNEE_TYPES.TEAM,
              ...team,
            },
          });
        });

        const connectedTeams = await this.slackService.getConnectedTeams();

        users.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });

        return data;
      },
    });
  }

  public async tags({
    callback,
    event,
    side,
  }: {
    callback: (id: string, name: any, object: any) => void;
    event: any;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      showImages: false,
      showSearch: true,
      placeholderLink: `/${routes.DASHBOARD}/tags`,
      dynamicCreationCallback: async (text: string) => {
        const tag = await this.tagsService.create({
          name: text,
          description: '',
          autoTaggingEnabled: false,
        });
        callback(tag.id, text, tag);
      },
      callback,
      side,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const tagsResponse = await this.tagsService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        return tagsResponse.data.map((tag: Tag) => {
          return {
            title: tag.name,
            id: tag.id,
            object: tag,
          };
        });
      },
    });
  }

  public async customers({
    callback,
    event,
    selectedCustomerId,
    side,
  }: {
    callback: (id: string, customerName: string, customer: any) => void;
    event: any;
    selectedCustomerId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedCustomerId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const response = await this.customersService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        return response.data.map((customer: Customer) => {
          return {
            title: customer.name,
            id: customer.id,
            object: customer,
          };
        });
      },
    });
  }

  public async statuses({
    callback,
    event,
    selectedStatusId,
    side,
  }: {
    callback: (id: string, user: any) => void;
    event: any;
    selectedStatusId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedStatusId,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return STATUS_OPTIONS;
      },
    });
  }

  public async priorities({
    callback,
    event,
    selectedPriority,
    side,
  }: {
    callback: (id: string, user: any) => void;
    event: any;
    selectedPriority?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedPriority,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return PRIORITY_OPTIONS;
      },
    });
  }

  public async sourceTypes({
    callback,
    event,
    selectedSourceTypeId,
    side,
  }: {
    callback: (id: string, user: any) => void;
    event: any;
    selectedSourceTypeId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedSourceTypeId,
      showImages: false,
      showSearch: false,
      callback,
      side,
      loadData: async () => {
        return [
          {
            title: 'Slack',
            id: 'slack',
          },
          {
            title: 'Email',
            id: 'email',
          },
          {
            title: 'In-app',
            id: 'widget',
          },
          {
            title: 'Dashboard',
            id: 'dashboard',
          },
          {
            title: 'Microsoft Teams',
            id: 'microsoft-teams',
          },
        ];
      },
    });
  }

  public async conversations({
    callback,
    event,
    customerId,
    where = [],
  }: {
    callback: (id: string, conversation: any) => void;
    event: any;
    customerId?: string;
    where?: WhereClause[];
  }) {
    this.showPopover({
      event,
      showImages: false,
      showSearch: true,
      callback,
      loadData: async (searchText: string) => {
        const fullWhere: WhereClause[] = [...where];

        if (customerId) {
          fullWhere.push({
            field: 'customerId',
            operator: '==',
            value: customerId,
          });
        }

        const response = await this.conversationsService.list({
          where: fullWhere,
          limit: 100,
          search: searchText || undefined,
          order: ['createdAt'],
          descending: true,
        });

        return response.data.map((conversation: Conversation) => {
          return {
            title: `[${conversation.friendlyId}] ${conversation.title}`,
            id: conversation.id,
            object: conversation,
          };
        });
      },
    });
  }

  public async templates({
    callback,
    event,
    selectedTemplateId,
    side,
    templateType,
    componentView,
  }: {
    callback: (id: string, title: string, template: any) => void;
    event: any;
    selectedTemplateId?: string;
    side?: PopoverSide;
    templateType?: 'slack' | 'email';
    componentView?: string;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedTemplateId,
      showImages: false,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.OUTBOUND}/templates`,
      componentView,
      callback,
      loadData: async (searchText: string) => {
        const params = templateType
          ? {
              type: templateType,
            }
          : {};

        const response: any = await this.apiService.getPromise(`/templates`, params);
        const data = [];

        response?.rows.map((template: Template) => {
          if (!searchText?.length || template.name.toLowerCase().includes(searchText.trim().toLowerCase())) {
            data.push({
              title: template.name,
              id: template.id,
              object: template,
            });
          }
        });
        return data;
      },
    });
  }

  public async channelMembers({
    callback,
    event,
    customerId,
    side,
  }: {
    callback: (id: string, name: string, member: any) => void;
    event: any;
    customerId: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: false,
      callback,
      side,
      loadData: async (_searchText: string) => {
        const response: any = await this.apiService.getPromise(`/customers/${customerId}/members`);

        return response?.map((member: any) => {
          return {
            title: member.user.name,
            id: member.user.id,
            image: member.user.photo,
            object: member,
          };
        });
      },
    });
  }

  public async mentionableUsers({
    callback,
    event,
    selectedUserId,
    side,
    dedupeInternalUsers,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedUserId?: string;
    side?: PopoverSide;
    dedupeInternalUsers?: boolean;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedUserId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'slackId',
            operator: '!=',
            value: null,
          },
          {
            field: 'status',
            operator: '==',
            value: 'active',
          },
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }
        const result = await this.usersService.list({
          includeExternalUsers: true,
          where,
          limit: 100,
          order: ['name'],
          descending: false,
          dedupeInternalUsers,
        });

        const data = [];

        const connectedTeams = await this.slackService.getConnectedTeams();

        result.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });

        return data;
      },
    });
  }

  public async allUsers({
    callback,
    event,
    selectedUserId,
    side,
    dedupeInternalUsers,
  }: {
    callback: (id: string, name: string, user: any) => void;
    event: any;
    selectedUserId?: string;
    side?: PopoverSide;
    dedupeInternalUsers?: boolean;
  }) {
    this.showPopover({
      event,
      showImages: true,
      showSearch: true,
      side,
      selectedItemId: selectedUserId,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [
          {
            field: 'name',
            operator: '!=',
            value: null,
          },
        ];

        const result = await this.usersService.list({
          limit: 100,
          includeExternalUsers: true,
          dedupeInternalUsers,
          where,
          order: ['name', 'email'],
          search: searchText,
          descending: false,
        });

        const data = [];

        const connectedTeams = await this.slackService.getConnectedTeams();

        result.data.forEach((user) => {
          data.push({
            title: formatUserName(user, connectedTeams),
            id: user.id,
            image: user.photo,
            object: user,
          });
        });
        return data;
      },
    });
  }

  public async ticketTypes({
    callback,
    event,
    selectedTicketTypeId,
    showNoneOption = true,
    side,
  }: {
    callback: (id: string, title, data: TicketType) => void;
    event: any;
    selectedTicketTypeId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedTicketTypeId,
      showImages: false,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.SETTINGS}/${routes.TICKET_TYPES}`,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        const response = await this.ticketTypesService.list({
          where,
          limit: 100,
          order: ['name'],
          descending: false,
        });

        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.data.forEach((ticketType: TicketType) => {
          data.push({
            title: ticketType.name,
            id: ticketType.id,
            object: ticketType,
          });
        });

        return data;
      },
    });
  }

  public async automations({
    callback,
    event,
    selectedAutomationId,
    showNoneOption,
    trigger,
    side,
  }: {
    callback: (id: string, name: string, data: Automation) => void;
    event: any;
    selectedAutomationId?: string;
    showNoneOption?: boolean;
    side?: PopoverSide;
    trigger?: {
      type?: string;
      object?: string;
      event?: string;
    };
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedAutomationId,
      showImages: false,
      showSearch: true,
      side,
      placeholderLink: `/${routes.DASHBOARD}/${routes.SETTINGS}/${routes.AUTOMATIONS}`,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        if (searchText?.length) {
          where.push({
            field: 'name',
            operator: 'like',
            value: `%${searchText}%`,
          });
        }

        if (trigger?.type) {
          where.push({
            field: 'trigger.type',
            operator: '==',
            value: trigger.type,
          });
        }

        if (trigger?.object) {
          where.push({
            field: 'trigger.object',
            operator: '==',
            value: trigger.object,
          });
        }

        if (trigger?.event) {
          where.push({
            field: 'trigger.event',
            operator: '==',
            value: trigger.event,
          });
        }

        const response = await this.automationsService.list({
          where,
          limit: null,
          order: ['name'],
          descending: false,
        });

        const data = [];

        if (showNoneOption && (!searchText?.length || NONE_TEXT.toLowerCase().includes(searchText.toLowerCase()))) {
          data.push({
            title: NONE_TEXT,
            id: null,
            image: environment.assetsBaseUrl + '/imgs/1x1.png',
            object: {},
          });
        }

        response.data.forEach((automation: Automation) => {
          data.push({
            title: automation.name,
            id: automation.id,
            object: automation,
          });
        });

        return data;
      },
    });
  }

  public async slas({
    callback,
    event,
    selectedSlaId,
    side,
  }: {
    callback: (id: string, title: string, sla: any) => void;
    event: any;
    selectedSlaId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedSlaId,
      showImages: false,
      showSearch: false,
      side,
      callback,
      loadData: async (_searchText: string) => {
        const result: any = await this.apiService.postPromise('/support-step-rulesets/list', {
          order: ['createdAt'],
          descending: false,
        });

        const data = [
          {
            title: 'Default',
            id: null,
            object: null,
          },
        ];

        result.data.forEach((sla) => {
          data.push({
            title: sla.name,
            id: sla.id,
            object: sla,
          });
        });

        return data;
      },
    });
  }

  public async externalCrmAccounts({
    callback,
    event,
    selectedAccountId,
    side,
  }: {
    callback: (id: string, title: string, sla: any) => void;
    event: any;
    selectedAccountId?: string;
    side?: PopoverSide;
  }) {
    this.showPopover({
      event,
      selectedItemId: selectedAccountId,
      showImages: false,
      showSearch: true,
      side,
      callback,
      loadData: async (searchText: string) => {
        const where: WhereClause[] = [];

        const options: any = {
          limit: 100,
          order: ['name'],
        };

        if (searchText) {
          options.where = [
            {
              field: 'name',
              operator: 'like',
              value: `%${searchText}%`,
            },
          ];
        }

        const response: ListResponse<ExternalCrmAccount> = await this.apiService.postPromise(
          '/external-crm/accounts/list',
          options,
        );

        const data = [];

        response.data.forEach((account: ExternalCrmAccount) => {
          data.push({
            title: account.name,
            id: account.id,
            object: account,
          });
        });

        return data;
      },
    });
  }
}
